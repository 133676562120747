@import "./variables.css";

html {
    --text-primary: var(--gray-900);
    --button-primary-bg: var(--blue-600);
    --button-primary-bg_hover: var(--blue-700);
    --button-primary-fg: var(--white);
    --button-primary-fg_hover: var(--white);
    --button-secondary-bg: var(--white);
    --button-secondary-fg: var(--gray-700);
    --button-secondary-fg_hover: var(--gray-800);
    --button-secondary-border: var(--gray-300);
    --button-secondary-border_hover: var(--gray-300);
    --button-secondary-bg_hover: var(--gray-iron-50);
    --colors-border-secondary: var(--gray-200);
    --colors-background-secondary: var(--gray-50);
    --canvas-background-primary: var(--gray-blue-25);
    --colors-background-tertiary: var(--gray-true-100);
    --table-text-heading-color: var(--gray-500);
    --colors-background-primary: var(--white);
    --colors-background-primary_hover: var(--gray-50);
    --colors-text-secondary_hover: var(--gray-800);
    --colors-text-tertiary: var(--gray-600);
    --colors-text-secondary: var(--gray-700);
    --colors-utility-success: var(--success-700);
    --colors-text-primary-900: var(--gray-900);

    --button-secondary-color-bg: var(--white);
    --button-secondary-color-bg_hover: var(--blue-50);
    --button-secondary-color-fg: var(--blue-700);
    --button-secondary-color-fg_hover: var(--blue-800);
    --button-secondary-color-border: var(--blue-300);
    --button-secondary-color-border_hover: var(--blue-300);

    --button-tertiary-color-bg: var(--white);
    --button-tertiary-color-bg_hover: var(--blue-50);
    --button-tertiary-color-fg: var(--blue-700);
    --button-tertiary-color-fg_hover: var(--blue-800);
    --button-tertiary-color-border: var(--blue-500);
    --button-tertiary-color-border_hover: var(--blue-50);

    --utility-brand-500: var(--blue-500);
    --utility-brand-300: var(--blue-300);


}

html [data-theme="dark"] {
    --text-primary: var(--gray-900);
    --button-primary-bg: var(--blue-600);
    --button-primary-bg_hover: var(--blue-700);
    --button-primary-fg: var(--white);
    --button-primary-fg_hover: var(--white);
    --button-secondary-bg: var(--white);
    --button-secondary-fg: var(--gray-700);
    --button-secondary-fg_hover: var(--gray-800);
    --button-secondary-border: var(--gray-300);
    --button-secondary-border_hover: var(--gray-300);
    --button-secondary-bg_hover: var(--gray-iron-50);
    --colors-border-secondary: var(--gray-200);
    --colors-background-secondary: var(--gray-50);
    --canvas-background-primary: var(--gray-blue-25);
    --colors-background-tertiary: var(--gray-true-100);
    --table-text-heading-color: var(--gray-500);
    --colors-background-primary: var(--white);
    --colors-background-primary_hover: var(--gray-50);
    --colors-text-secondary_hover: var(--gray-800);
    --colors-text-tertiary: var(--gray-600);
    --colors-text-secondary: var(--gray-700);
    --colors-utility-success: var(--success-700);

    --button-secondary-color-bg: var(--white);
    --button-secondary-color-bg_hover: var(--blue-50);
    --button-secondary-color-fg: var(--blue-700);
    --button-secondary-color-fg_hover: var(--blue-800);
    --button-secondary-color-border: var(--blue-300);
    --button-secondary-color-border_hover: var(--blue-300);
    --button-tertiary-color-bg: var(--white);
    --button-tertiary-color-bg_hover: var(--blue-50);
    --button-tertiary-color-fg: var(--blue-700);
    --button-tertiary-color-fg_hover: var(--blue-800);
    --button-tertiary-color-border: var(--blue-500);
    --button-tertiary-color-border_hover: var(--blue-50);
    --utility-brand-500: var(--blue-500);
    --colors-text-primary-900: var(--gray-900);

    /* Contrast Borders */
    --avatar-contrast-border: #00000014;

}