$color-white: #ffffff;

$color-gray-5: #f1f3f6;

$color-xmart: #0033cc;
$color-xmart-seethrough: rgba(219, 88, 103, 0.1);

$color-pink: #ff008a;
$color-pink-seethrough: rgba(255, 0, 138, 0.1);

$color-purple: #2d2ace;
$color-purple-seethrough: rgba(45, 42, 206, 0.1);

$color-yellow: #fcb831;
$color-yellow-seethrough: rgba(252, 184, 49, 0.1);

$color-darkGrey: #666666;
$color-Grey: #707070;
$color-header: rgba(0, 0, 0, 0.65);

$color-green: #50ce89;
$color-green-seethrough: rgba(80, 206, 137, 0.1);
$color-shade-black:#181D27;
$color-line:#E9EAEB;
:root {
  /* Colors */
  --blue-deep: rgb(0, 121, 191);
  --blue-light: lightblue;
  --blue-lighter: #d9fcff;
  --blue-soft: #e6fcff;
  --blue-status: #00c4c4;
  --blue-status-dark: #000eb7;

  --black: #4d4d4d;
  --shadow: rgba(0, 0, 0, 0.2);

  --grey-darker: #c1c7d0;
  --grey-dark: #e2e4e6;
  --grey-medium: #dfe1e5;
  --grey-n30: #ebecf0;
  --grey-light: #f4f5f7;
  --grey-dark-title: #666666;
  --grey-dark-text: #979797;
  --grey-light-title: #b2b2b2;
  --grey-status: #727272;
  --grey-modal-title: #707070;
  --grey-background-color: #f1f3f6;
  --grey-sla-text-color: #f9f9f9;
  --grey-lighter: #d9d9d9;

  --green: rgb(185, 244, 188);
  --green-status: #209024;

  --red-status: #ff4c2e;
  --red-failed-status: #970000;

  --orange-yellow: #f1b100;
  --white: #ffffff;

  --purple: rebeccapurple;
  --purple-status: #3b005e;
  --purple-cancellation-progress: #5f3d87;

  --orange: #ff991f;
  --xmart-red: #0033cc;

  --all-zones: #818182;
  --hot-zone: #ec7373;
  --warm-zone: #fcb831;
  --cold-zone: #50ce89;
  --card-text: #464e5f;

  --pending-color: #005a80;
  --disabled-color: #ec5453;
  --success-color: #28c66f;

  /* Grid and Spacing */
  --grid: 8px;
  --border-radius: 2px;

  /* States */
  --state-incomplete: #000000;
  --state-open: #f98e00;
  --state-rfp: #0099ff;
  --state-packed: #758350;
  --state-shipping: #1c4d99;
  --state-complete: #209024;
  --state-canceled: #ff00a1;
  --state-returned: #9f00ff;
  --state-failed: #f1cf00;
  --state-cip: #9572aa;
  --state-draft: #415cff;
  --state-need-approval: #920000;
}

/* You can replace rowStyle, colStyle, and gutter as mixins or class utilities */
.row-style {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
}

.col-style {
  margin-bottom: 16px;
}

/* Example of using a gutter variable */
.gutter {
  --gutter: 16px;
  padding: 0 var(--gutter);
}
