@import "./colors";

.mb8 {
  margin-bottom: 8px !important;
}

.mb30 {
  margin-bottom: 30px !important;
}

.xmartButton {
  background: var(--button-primary-bg);
  color: var(--button-primary-fg);
  border: none;
  min-width: 190px;
  margin: 0px auto;

  &:hover {
    background: var(--button-primary-bg_hover);
    color: var(--button-primary-fg_hover);
    border: none;
    width: 190px;
    margin: 0px auto;
  }

  &:active {
    background: $color-xmart;
    color: $color-white;
    border: none;
    width: 190px;
    margin: 0px auto;
  }

  &:focus {
    background: $color-xmart;
    color: $color-white;
    border: none;
    width: 190px;
    margin: 0px auto;
  }
}

.modalHeader {
  font-size: 19px;
  color: $color-Grey;
  font-weight: bold;
}

.header-text {
  color: $color-header;
  font-size: 17px;
  font-weight: bold;
  margin-bottom: 25px;
}

.content-card {
  padding: 50px;
  margin-bottom: 25px;
}

.standard-input {
  max-height: 32px;
}

.input-label {
  color: $color-darkGrey;
  font-weight: 500;
}

[class|="flex"] {
  display: flex;
}

[class*="row"] {
  flex-direction: row;
  flex-wrap: wrap;
}

[class*="column"] {
  flex-direction: column;
}

[class*="center"] {
  justify-content: center;
  justify-items: center;
  align-items: center;
}

[class*="even"] {
  justify-content: space-evenly;
  align-items: center;
}

[class*="start"] {
  justify-content: flex-start;
  align-items: center;
}