:root {
    /* Base Colors */
    --white: #FFFFFF;
    --black: #000000;

    /* Gray Colors */
    --gray-25: #FDFDFD;
    --gray-50: #FAFAFA;
    --gray-100: #F5F5F5;
    --gray-200: #E9EAEB;
    --gray-300: #D5D7DA;
    --gray-400: #A4A7AE;
    --gray-500: #717680;
    --gray-600: #535862;
    --gray-700: #414651;
    --gray-800: #252B37;
    --gray-900: #181D27;
    --gray-950: #0A0D12;

    /* Primary Blue */
    --blue-25: #F0F4FF;
    --blue-50: #D0D9FF;
    --blue-100: #A1B4FF;
    --blue-200: #6A90FF;
    --blue-300: #3F73FF;
    --blue-400: #1553FF;
    --blue-500: #003BE6;
    --blue-600: #0033CC;
    --blue-700: #002AA3;
    --blue-800: #002080;
    --blue-900: #00134D;
    --blue-950: #000A1A;

    /* Primary Mint Green */
    --mint-green-25: #E5FCFF;
    --mint-green-50: #B8F7FC;
    --mint-green-100: #81F0F8;
    --mint-green-200: #4BE8F3;
    --mint-green-300: #1FE0ED;
    --mint-green-400: #00D1E3;
    --mint-green-500: #00D8E2;
    --mint-green-600: #00BECB;
    --mint-green-700: #00A4B3;
    --mint-green-800: #008A9C;
    --mint-green-900: #005F6D;
    --mint-green-950: #003741;

    /* Error Colors */
    --error-25: #FFFBFA;
    --error-50: #FEF3F2;
    --error-100: #FEE4E2;
    --error-200: #FECDCA;
    --error-300: #FDA29B;
    --error-400: #F97066;
    --error-500: #F04438;
    --error-600: #D92D20;
    --error-700: #B42318;
    --error-800: #912018;
    --error-900: #7A271A;
    --error-950: #55160C;

    /* Warning Colors */
    --warning-25: #FFFCF5;
    --warning-50: #FFFAEB;
    --warning-100: #FEF0C7;
    --warning-200: #FEDF89;
    --warning-300: #FEC84B;
    --warning-400: #FDB022;
    --warning-500: #F79009;
    --warning-600: #DC6803;
    --warning-700: #B54708;
    --warning-800: #93370D;
    --warning-900: #7A2E0E;
    --warning-950: #4E1D09;

    /* Success Colors */
    --success-25: #F6FEF9;
    --success-50: #ECFDF3;
    --success-100: #DCFAE6;
    --success-200: #ABEFC6;
    --success-300: #75E0A7;
    --success-400: #47CD89;
    --success-500: #17B26A;
    --success-600: #079455;
    --success-700: #067647;
    --success-800: #085D3A;
    --success-900: #074D31;
    --success-950: #053321;

    /* Secondary Colors: Gray Blue */
    --gray-blue-25: #FCFCFD;
    --gray-blue-50: #F8F9FC;
    --gray-blue-100: #EAECF5;
    --gray-blue-200: #D5D9EB;
    --gray-blue-300: #B3B8DB;
    --gray-blue-400: #717BBC;
    --gray-blue-500: #4E5BA6;
    --gray-blue-600: #3E4784;
    --gray-blue-700: #363F72;
    --gray-blue-800: #293056;
    --gray-blue-900: #101323;
    --gray-blue-950: #0D0F1C;

    /* Secondary Colors: Gray Cool */
    --gray-cool-25: #FCFCFD;
    --gray-cool-50: #F9F9FB;
    --gray-cool-100: #EFF1F5;
    --gray-cool-200: #DCDFEA;
    --gray-cool-300: #B9C0D4;
    --gray-cool-400: #7D89B0;
    --gray-cool-500: #5D6B98;
    --gray-cool-600: #4A5578;
    --gray-cool-700: #404968;
    --gray-cool-800: #30374F;
    --gray-cool-900: #111322;
    --gray-cool-950: #0E101B;

    /* Secondary Colors: Gray Modern */
    --gray-modern-25: #FCFCFD;
    --gray-modern-50: #F8FAFC;
    --gray-modern-100: #EEF2F6;
    --gray-modern-200: #E3E8EF;
    --gray-modern-300: #CDD5DF;
    --gray-modern-400: #9AA4B2;
    --gray-modern-500: #697586;
    --gray-modern-600: #4B5565;
    --gray-modern-700: #364152;
    --gray-modern-800: #202939;
    --gray-modern-900: #121926;
    --gray-modern-950: #0D121C;

    /* Secondary Colors: Gray Neutral */
    --gray-neutral-25: #FCFCFD;
    --gray-neutral-50: #F9FAFB;
    --gray-neutral-100: #F3F4F6;
    --gray-neutral-200: #E5E7EB;
    --gray-neutral-300: #D2D6DB;
    --gray-neutral-400: #9DA4AE;
    --gray-neutral-500: #6C737F;
    --gray-neutral-600: #4D5761;
    --gray-neutral-700: #384250;
    --gray-neutral-800: #1F2A37;
    --gray-neutral-900: #111927;
    --gray-neutral-950: #0D121C;

    /* Secondary Colors: Gray Iron */
    --gray-iron-25: #FCFCFC;
    --gray-iron-50: #FAFAFA;
    --gray-iron-100: #F4F4F5;
    --gray-iron-200: #E4E4E7;
    --gray-iron-300: #D1D1D6;
    --gray-iron-400: #A0A0AB;
    --gray-iron-500: #70707B;
    --gray-iron-600: #51525C;
    --gray-iron-700: #3F3F46;
    --gray-iron-800: #26272B;
    --gray-iron-900: #18181B;
    --gray-iron-950: #131316;

    /* Secondary Colors: Gray True */
    --gray-true-25: #FCFCFC;
    --gray-true-50: #F7F7F7;
    --gray-true-100: #F5F5F5;
    --gray-true-200: #E5E5E5;
    --gray-true-300: #D6D6D6;
    --gray-true-400: #A3A3A3;
    --gray-true-500: #737373;
    --gray-true-600: #525252;
    --gray-true-700: #424242;
    --gray-true-800: #292929;
    --gray-true-900: #141414;
    --gray-true-950: #0F0F0F;

    /* Secondary Colors: Gray Warm */
    --gray-warm-25: #FDFDFC;
    --gray-warm-50: #FAFAF9;
    --gray-warm-100: #F5F5F4;
    --gray-warm-200: #E7E5E4;
    --gray-warm-300: #D7D3D0;
    --gray-warm-400: #A9A29D;
    --gray-warm-500: #79716B;
    --gray-warm-600: #57534E;
    --gray-warm-700: #44403C;
    --gray-warm-800: #292524;
    --gray-warm-900: #1C1917;
    --gray-warm-950: #171412;

    /* Gray Gradients */
    --gray-gradient-600-500-90deg: linear-gradient(90deg, #535862, #717680);
    --gray-gradient-700-600-45deg: linear-gradient(45deg, #414651, #535862);
    --gray-gradient-800-600-45deg: linear-gradient(45deg, #252B37, #535862);
    --gray-gradient-800-600-90deg: linear-gradient(90deg, #252B37, #535862);
    --gray-gradient-800-600-26deg: linear-gradient(26.5deg, #252B37, #535862);
    --gray-gradient-900-700-45deg: linear-gradient(45deg, #181D27, #414651);
    --gray-gradient-900-600-45deg: linear-gradient(45deg, #181D27, #535862);
    --gray-gradient-50-white-180deg: linear-gradient(180deg, #FAFAFA, #FFFFFF);
    --gray-gradient-100-white-180deg: linear-gradient(180deg, #F5F5F5, #FFFFFF);
    --gray-gradient-100-25-180deg: linear-gradient(180deg, #F5F5F5, #FDFDFD);
    --gray-gradient-200-25-180deg: linear-gradient(180deg, #E9EAEB, #FDFDFD);
    --gray-gradient-200-50-180deg: linear-gradient(180deg, #E9EAEB, #FAFAFA);
    --gray-gradient-200-100-180deg: linear-gradient(180deg, #E9EAEB, #F5F5F5);

    /* Brand Gradients */
    --brand-gradient-600-500-90deg: linear-gradient(90deg, #7F56D9, #9E77ED);
    --brand-gradient-700-600-45deg: linear-gradient(45deg, #6941C6, #7F56D9);
    --brand-gradient-800-600-45deg: linear-gradient(45deg, #53389E, #7F56D9);
    --brand-gradient-800-600-90deg: linear-gradient(90deg, #53389E, #7F56D9);
    --brand-gradient-800-600-26deg: linear-gradient(26.5deg, #53389E, #7F56D9);
    --brand-gradient-900-700-45deg: linear-gradient(45deg, #42307D, #6941C6);
    --brand-gradient-900-600-45deg: linear-gradient(45deg, #42307D, #7F56D9);

    /* Linear Gradients */
    --linear-gradient-01: linear-gradient(180deg, #A5C0EE, #FBC5EC);
    --linear-gradient-02: linear-gradient(180deg, #FBC2EB, #A18CD1);
    --linear-gradient-03: linear-gradient(180deg, #FFD1FF, #FAD0C4);
    --linear-gradient-04: linear-gradient(225deg, #FAD0C4, #FF9A9E);
    --linear-gradient-05: linear-gradient(270deg, #FCB69F, #FFECD2);
    --linear-gradient-06: linear-gradient(180deg, #FECFEF, #FF989C);
    --linear-gradient-07: linear-gradient(45deg, #FF9DE4, #FFEAF6);
    --linear-gradient-08: linear-gradient(180deg, #E6DEE9, #FDCAF1);
    --linear-gradient-09: linear-gradient(45deg, #A6C0FE, #FFEAF6);
    --linear-gradient-10: linear-gradient(0deg, #CFC7F8, #EBBBA7);
    --linear-gradient-11: linear-gradient(135deg, #FCCB90, #D57EEB);
    --linear-gradient-12: linear-gradient(45deg, #7B6AE0, #FFBB89);
    --linear-gradient-13: linear-gradient(135deg, #E0C3FC, #8EC5FC);
    --linear-gradient-14: linear-gradient(0deg, #FED6E3, #A8EDEA);
    --linear-gradient-15: linear-gradient(135deg, #F5F7FA, #C3CFE2);
    --linear-gradient-16: linear-gradient(45deg, #DFD1C5, #FFF6EB);
    --linear-gradient-17: linear-gradient(135deg, #FFF6B7, #FB758A);
    --linear-gradient-18: linear-gradient(45deg, #FF7EC7, #FFED46);
    --linear-gradient-19: linear-gradient(0deg, #FEAFA8, #F5EFEF);
    --linear-gradient-20: linear-gradient(45deg, #FBFCDB, #E9DEFA);
    --linear-gradient-21: linear-gradient(0deg, #FFF1EB, #ACE0F9);
    --linear-gradient-22: linear-gradient(0deg, #C1DFC4, #DEECDD);
    --linear-gradient-23: linear-gradient(45deg, #A1C4FD, #C2E9FB);
    --linear-gradient-24: linear-gradient(0deg, #ACCBEE, #E7F0FD);
    --linear-gradient-25: linear-gradient(0deg, #84FAB0, #ACCBEE);
    --linear-gradient-26: linear-gradient(45deg, #39A0FF, #8FFF85);
    --linear-gradient-27: linear-gradient(270deg, #74EBD5, #9FACE6);
    --linear-gradient-28: linear-gradient(45deg, #4A879A, #C5EDF5);
    --linear-gradient-29: linear-gradient(45deg, #9890E3, #B1F4CF);
    --linear-gradient-30: linear-gradient(45deg, #7CDADA, #F697AA);
    --linear-gradient-31: linear-gradient(45deg, #B1FF96, #FFADF7);
    --linear-gradient-32: linear-gradient(0deg, #96FBC4, #F9F586);
    --linear-gradient-33: linear-gradient(45deg, #4DEF8E, #FFEB3A);
    --linear-gradient-34: linear-gradient(135deg, #F0FF00, #58CFFB);
    --linear-gradient-35: linear-gradient(0deg, #D1FDFF, #FDDB92);
    --linear-gradient-36: linear-gradient(0deg, #EBC0FD, #D9DED8);
    --linear-gradient-37: linear-gradient(45deg, #FFA4F6, #B7DCFF);
    --linear-gradient-38: linear-gradient(0deg, #CD9CF2, #F6F3FF);
    --linear-gradient-39: linear-gradient(315deg, #F5C8F5, #DADDFA);
    --linear-gradient-40: linear-gradient(0deg, #E6DEE9, #BDC2E8);
    --linear-gradient-41: linear-gradient(0deg, #6A85B6, #BAC8E0);
    --linear-gradient-42: linear-gradient(45deg, #8B8B8B, #EAEAEA);
    --linear-gradient-43: linear-gradient(135deg, #E2B0FF, #9F44D3);
    --linear-gradient-44: linear-gradient(135deg, #CE9FFC, #7367F0);
    --linear-gradient-45: linear-gradient(135deg, #72EDF2, #5151E5);
    --linear-gradient-46: linear-gradient(0deg, #A3BDED, #6991C7);
    --linear-gradient-47: linear-gradient(0deg, #FBC8D4, #9795F0);
    --linear-gradient-48: linear-gradient(0deg, #A7A6CB, #8989BA);
    --linear-gradient-49: linear-gradient(0deg, #D9AFD9, #97D9E1);
    --linear-gradient-50: linear-gradient(135deg, #81FFEF, #F067B4);
    --linear-gradient-51: linear-gradient(135deg, #DCB0ED, #9999CC);
    --linear-gradient-52: linear-gradient(135deg, #FFF5C3, #9452A5);
    --linear-gradient-53: linear-gradient(135deg, #F1CA74, #A64DB6);
    --linear-gradient-54: linear-gradient(45deg, #4D6AD0, #FF9D7E);
    --linear-gradient-55: linear-gradient(135deg, #FFCF71, #2376DD);
    --linear-gradient-56: linear-gradient(135deg, #E8D07A, #5312D6);
    --linear-gradient-57: linear-gradient(180deg, #BFD9FE, #DF89B5);
    --linear-gradient-58: linear-gradient(0deg, #FA71CD, #C471F5);
    --linear-gradient-59: linear-gradient(135deg, #43CBFF, #9708CC);
    --linear-gradient-60: linear-gradient(180deg, #7579FF, #B224EF);
    --linear-gradient-61: linear-gradient(45deg, #AD00FE, #00E0EE);
    --linear-gradient-62: linear-gradient(135deg, #89F7FE, #66A6FF);
    --linear-gradient-63: linear-gradient(0deg, #009EFD, #2AF598);
    --linear-gradient-64: linear-gradient(45deg, #FFB800, #FFF500);
    --linear-gradient-65: linear-gradient(135deg, #FFA8A8, #FCFF00);
    --linear-gradient-66: linear-gradient(45deg, #FF7A00, #FFD439);
    --linear-gradient-67: linear-gradient(135deg, #FFD3A5, #FD6585);
    --linear-gradient-68: linear-gradient(180deg, #F9D423, #E14FAD);
    --linear-gradient-69: linear-gradient(135deg, #F74FAC, #FCB24F);
    --linear-gradient-70: linear-gradient(135deg, #F49062, #FD371F);
    --linear-gradient-71: linear-gradient(45deg, #FF6C6C, #DD7BFF);
    --linear-gradient-72: linear-gradient(135deg, #F97794, #623AA2);
    --linear-gradient-73: linear-gradient(180deg, #C569CF, #EE609C);
    --linear-gradient-74: linear-gradient(0deg, #C7EAFD, #E8198B);
    --linear-gradient-75: linear-gradient(135deg, #F093FB, #F5576C);
    --linear-gradient-76: linear-gradient(135deg, #F6CEEC, #D939CD);
    --linear-gradient-77: linear-gradient(135deg, #EE9AE5, #5961F9);
    --linear-gradient-78: linear-gradient(270deg, #6A11CB, #2575FC);
    --linear-gradient-79: linear-gradient(45deg, #0017E4, #3793FF);
    --linear-gradient-80: linear-gradient(0deg, #00C6FB, #005BEA);
    --linear-gradient-81: linear-gradient(45deg, #4B73FF, #7CF7FF);
    --linear-gradient-82: linear-gradient(135deg, #5EFCE8, #736EFE);
    --linear-gradient-83: linear-gradient(0deg, #7028E4, #E5B2CA);
    --linear-gradient-84: linear-gradient(90deg, #7873F5, #EC77AB);
    --linear-gradient-85: linear-gradient(135deg, #B01EFF, #E1467C);
    --linear-gradient-86: linear-gradient(45deg, #D079EE, #8A88FB);
    --linear-gradient-87: linear-gradient(135deg, #C99FFF, #981ED2);
    --linear-gradient-88: linear-gradient(0deg, #9B23EA, #5F72BD);
    --linear-gradient-89: linear-gradient(135deg, #B39FFF, #6A1ED2);
    --linear-gradient-90: linear-gradient(45deg, #4300B1, #A531DC);
    --linear-gradient-91: linear-gradient(315deg, #764BA2, #667EEA);

     /*icons*/
     --icon-border:#CECFD1;

    /* Text&Spacing */
    --radius-none: 0px;
    --radius-xxs: 2px;
    --radius-xs: 4px;
    --radius-sm: 6px;
    --radius-md: 8px;
    --radius-lg: 10px;
    --radius-xl: 12px;
    --radius-2xl: 16px;
    --radius-3xl: 20px;
    --radius-4xl: 24px;
    --radius-full: 9999px;

    --spacing-none: 0px;
    --spacing-xxs: 2px;
    --spacing-xs: 4px;
    --spacing-sm: 6px;
    --spacing-md: 8px;
    --spacing-lg: 12px;
    --spacing-xl: 16px;
    --spacing-2xl: 20px;
    --spacing-3xl: 24px;
    --spacing-4xl: 32px;
    --spacing-5xl: 40px;
    --spacing-6xl: 48px;
    --spacing-7xl: 64px;
    --spacing-8xl: 80px;
    --spacing-9xl: 96px;
    --spacing-10xl: 128px;
    --spacing-11xl: 160px;

    /* display 2xl */
    --font-size-display-2xl: 72px;
    --line-height-display-2xl: 90px;
    --letter-spacing-2xl: -2%;
    /* display xl */
    --font-size-display-xl: 60px;
    --line-height-display-xl: 72px;
    --letter-spacing-2xl: -2%;
    /* display lg */
    --font-size-display-lg: 48px;
    --line-height-display-lg: 60px;
    --letter-spacing-lg: -2%;
    /* display md */
    --font-size-display-md: 36px;
    --line-height-display-md: 44px;
    --letter-spacing-lg: -2%;
    /* display sm */
    --font-size-display-sm: 30px;
    --line-height-display-sm: 38px;
    /* display xs */
    --font-size-display-sm: 24px;
    --line-height-display-sm: 32px;


    /* text xl */
    --font-size-text-xl: 20px;
    --line-height-text-xl: 30px;
    /* text lg */
    --font-size-text-lg: 18px;
    --line-height-text-lg: 28px;
    /* text md */
    --font-size-text-md: 16px;
    --line-height-text-md: 24px;
    /* text sm */
    --font-size-text-sm: 14px;
    --line-height-text-sm: 20px;
    /* text xs */
    --font-size-text-xs: 12px;
    --line-height-text-xs: 18px;

    /* Bold */
    --text-weight-bold: 700;
    /* Semi Bold */
    --text-weight-semi-bold: 600;
    /* Medium weight */
    --text-weight-medium: 500;
    /* Regular weight */
    --text-weight-regular: 400;

    
}